  
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/app-check';


const firebaseConfig = {
    apiKey: "AIzaSyDXSaQTZ-CfavGVa70sGJ81LvZ8UmDaZwY",
    authDomain: "collegebarisrael.firebaseapp.com",
    databaseURL: "https://collegebarisrael-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "collegebarisrael",
    storageBucket: "collegebarisrael.appspot.com",
    messagingSenderId: "1006275571633",
    appId: "1:1006275571633:web:923d13a30bf7b5dc345790",
    measurementId: "G-7PW4EK2Q41"
};

// init firebase
var myApp = firebase.initializeApp(firebaseConfig);

// init services 

const projectFirestore = myApp.firestore();
const projectAuth = myApp.auth();
const projectStorage = myApp.storage();
const projectFunctions= myApp.functions();
// projectFunctions.useEmulator("localhost", 5001);
const FireStore = firebase.firestore;
const projectDataBase = myApp.database();
const projectDb = firebase.database;



// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore,projectStorage, projectAuth, timestamp, FireStore, projectFunctions, projectDataBase, projectDb};