import { createStore } from 'vuex'
import { projectFirestore, projectFunctions } from '../firebase/config';
import {slide_pop_successs} from '../Methods/Msgs';
import { check_active_shift } from '../Methods/Shift';

export default createStore({
  state: {
    user:null,
    loader:true,
    menu: false,
    shift: null,
    workers: [],
  },
  mutations: {
  },
  actions: {
    async set_user({ commit }, user){
      if(user){
        this.state.user = await projectFirestore.collection('Users').doc(user.uid).get()
        .then(doc => {
          if(doc.exists){
            slide_pop_successs(`ברוך הבא ${doc.data().name}`, 2500)
            return doc.data();
          }
        })
        let role = await projectFunctions.httpsCallable("check_role")()
        if(role.data > 99){
          //check active shifts
          check_active_shift();
        }
        
      }else{
        this.state.user = null;
      }
    }
  },
  modules: {
  }
})
