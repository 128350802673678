  
import { ElNotification } from "element-plus";
import Swal from 'sweetalert2'

const slide_pop_error = (text , duration = 2500) => {
    ElNotification({
        title: "שגיאה!",
        type: "error",
        message: text,
        showClose: false,
        position: 'top-right',
        duration: duration,
        zIndex: '10000000'
    });
}

const slide_pop_successs = (text, duration = 2500) => {
    ElNotification({
        title: "הצלחה",
        type: "success",
        message: text,
        showClose: false,
        duration: duration,
        position: 'top-right',
        zIndex: '10000000'
    });
}

const alert=(icon,title,text)=>{
    return Swal.fire({
        icon,
        title,
        text,
    })
}

const alert_confirm=(title)=>{
    Swal.fire({
        title,
        icon: 'question',
        iconHtml: '?',
        confirmButtonText: 'כן',
        cancelButtonText: 'לא',
        showCancelButton: true,
        showCloseButton: true
      })
}



export{slide_pop_error, slide_pop_successs,alert,alert_confirm}