<template>
    <div class="main-nav">
        <div class="menu-toggle" @click="open_main_menu">
            <i class="material-icons">menu</i>
        </div>
        <div class="top-btns">
            <div class="agents-portal" @click="go_to('/work')">
                <p id="agents-portal-btn">פורטל עובדים</p>
            </div>
        </div>
        <div class="logo" @click="go_to('/')">
            <p>College Bar</p>
            <img src="@/assets/main_logo.png" alt="">
        </div>
    </div>
</template>

<script>
import router from '../../router';
import store from '../../store';

export default {
setup(){
    const go_to = (path) => {
        router.push(path)
    }

    const open_main_menu = () => {
        store.state.menu = !store.state.menu
    }

    return{
        go_to, open_main_menu
    }
}
}
</script>

<style scoped>
.main-nav{
    direction: rtl;
    position: relative;
    width: 100%;
    height: 60px;
    background: var(--secondary);
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 60px calc(100% - 60px - 140px) 140px;
}
.logo{
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    flex-direction: row;
    align-items: center;
}
.logo img{
    max-width: 50%;
    max-height: 90%;
}
.logo p{
    width: auto;
    text-align: center;
    color: var(--yellow);
    text-shadow: 0 2px 3px rgba(15, 13, 13, 0.192);
}
.menu-toggle{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-toggle i{
    font-size: 60px;
    color: var(--light);
    text-shadow: 0 2px 3px rgba(15, 13, 13, 0.192);
    cursor: pointer;
}
.agents-portal{
    width: 120px;
    height: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#agents-portal-btn{
    background: var(--purple);
    padding: 10px;
    border-radius: 5px;
    color: var(--light);
    text-shadow: 0 1px 3px black;
    font-weight: 400;
    cursor: pointer;
}
</style>