import Swal from "sweetalert2";
import { projectFirestore } from "../firebase/config";
import store from "../store";
import { slide_pop_error, slide_pop_successs } from "./Msgs";
import router from '../router';

const is_active_shift = () => {
    if(store.state.shift && store.state.shift.active){
        return true
    }else{
        return false
    }
}

const new_shift = async () => {
    Swal.fire({
        icon: 'info',
        title: 'פתיחת משמרת חדשה',
        html:   `<h2>שלום, ${store.state.user.name}</h2> <br>` +
                `אם תרצה להוסיף הערה למי שסוגר את המשמרת, רשום אותה בשדה הבא:`,
        input: 'text',
        cancelButtonText: 'ביטול',
        showCancelButton: true,
        confirmButtonText: 'פתח'
    }).then((res) => {
        if(res.isConfirmed){
            create_clean_shift(res.value)
            update_shift_in_db();
        }
    })
}

const create_clean_shift = (note) => {
    store.state.shift = {
        note: note,
        active: true,
        workers: [],
        start_date: new Date(),
        end_date: null,
        user_open: store.state.user.name,
        total_cash: 0,
        total_credit: 0,
        total_orders: 0,
        total_cancels: 0,
        z_number: 0
    }
}

const check_active_shift = async () => {
    const doc = await projectFirestore.collection('Shifts').doc('current').get()
    if(doc.exists){
        let shift = doc.data()
        shift.start_date = shift.start_date.toDate()
        shift.workers.forEach(worker => {
            if(worker.shift.start_time){
                worker.shift.start_time = worker.shift.start_time.toDate()
            }
            if(worker.shift.end_time){
                worker.shift.end_time = worker.shift.end_time.toDate()
            }
            if(worker.service.start_time){
                worker.service.start_time = worker.service.start_time.toDate()
            }
            if(worker.service.end_time){
                worker.service.end_time = worker.service.end_time.toDate()
            }
        })
        store.state.shift = shift;
        slide_pop_successs("קיימת משמרת פעילה")
    }else{
        return false
    }
}

const update_shift_in_db = async () =>{ 
    await projectFirestore.collection('Shifts').doc('current').set(store.state.shift);
    return true
}

const finish_shift = async () => {
    let shift = store.state.shift
    for(let i =0; i< shift.workers.length; i++){
        let worker = shift.workers[i];
        if(worker.shift.active){
            slide_pop_error("עדיין קיימים עובדים במשמרת!")
            return
        }
        if(worker.service.active){
            slide_pop_error("עדיין קיימים עובדים בשירות!")
            return
        }
    }

    if(!shift.note || shift.note.length <=3){
        shift.note = 'אין הערות'
    }
    await Swal.fire({
        icon: 'warning',
        background: '#283046',
        title: '<p style="color:white;">הערת פותח המשמרת</p>',
        html: `<p style="color:white;">${shift.note}</p>`,
        confirmButtonText: 'מאשר וסיים',
        cancelButtonText: 'ביטול',
        showCancelButton: true,
        confirmButtonColor: '#28c76f',
        focusCancel: true,
    }).then(async res => {
        if(res.isDismissed || res.isDismissed){
            return
        }
        if(res.isConfirmed){
            console.log('סוגר משמרת');
            shift.active = false;
            await close_shift();
        }
    })
}

const close_shift = async () => {
    let shift = store.state.shift
    store.state.loader = true;
    const shift_doc = projectFirestore.collection('Shifts').doc()
    shift.uid = shift_doc.id
    shift.end_date = new Date();
    shift_doc.set(shift)
    await projectFirestore.collection('Shifts').doc('current').delete();
    store.state.shift = null;
    await router.push('lastshift');
    store.state.loader = false;
    slide_pop_successs("המשמרת נסגרה בהצלחה! לילה טוב :)")
}


export{is_active_shift, new_shift, update_shift_in_db, check_active_shift, finish_shift, close_shift}