import {projectAuth} from '../firebase/config'
import {slide_pop_error, slide_pop_successs} from '../Methods/Msgs';
import router from '../router';
import store from '../store';

const log_out = async () => {
    if(projectAuth.currentUser){
        await projectAuth.signOut()
        slide_pop_successs("התנתקת בהצלחה!")
        store.state.menu = false;
        router.replace('/');
    }
}

export{log_out}