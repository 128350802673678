<template>
    <div class="user-status">
        <div class="log-in" v-if="!user">
            <el-button type="primary" style="width:45%; text-align:center;" @click="go('login')">התחבר</el-button>
            <el-button type="primary" style="width:45%;" @click="go('register')">הרשמה</el-button>
        </div>
        <div class="user" v-if="user">
            <div class="online"></div>
            <div class="user-image">
                <img :src="user.profile_image" alt="">
            </div>
            <div class="user-name">
                <p>{{user.name}}</p>
                <p style="font-size: 14px;">{{user.email}}</p>
            </div>
            <div class="user-options">
                <i class="material-icons" style="color:var(--purple)">account_circle</i>
                <i class="material-icons" style="color:var(--danger)" @click="log_out">logout</i>
            </div>
        </div>

    </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import store from '../../store'
import router from '../../router'
import {log_out} from '../../Methods/Users';
export default {
setup(){
    const user = ref(computed(() => {
        return store.state.user
    }))

    const go = (path) => {
        router.push(`/${path}`)
        store.state.menu = false;
    }
    
    return{
        user, go, log_out
    }
}
}
</script>

<style scoped>
.user-status{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    background: var(--main);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.log-in{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
    height: 100%;
}
.user{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.online{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
    background: var(--success);
    border-radius: 50%;
    box-shadow: 0 0 5px var(--success);
}
.user-image{
    width: 80px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}
.user-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
.user-name{
    width: calc(100% - 80px - 80px - 5px);
    height: 100%;
    font-size: 18px;
    color: var(--yellow);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.user-options{
    width: 80px;
    height: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.user-options i{
    cursor: pointer;
    transition: 0.25s;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.384);
}
.user-options i:hover{
    transform: scale(1.1);
}
</style>