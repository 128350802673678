import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {projectAuth, projectFunctions} from './firebase/config';
import locale from 'element-plus/lib/locale/lang/he';
import 'element-plus/lib/theme-chalk/index.css';
import ElementPlus from 'element-plus';


projectAuth.onAuthStateChanged(async user => {
    store.state.loader = true;
    await store.dispatch("set_user", user);
    store.state.loader = false;
    // await store.dispatch("refresh_profile", user);
});

createApp(App)
.use(store)
.use(router)
.use(ElementPlus, {locale})
.mount('#app')
