import { createRouter, createWebHistory } from 'vue-router'
import {projectFunctions} from '../firebase/config'
import Home from '../views/Home.vue'
import store from '../store';
import Swal from 'sweetalert2';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/work',
    name: 'work',
    component: () => import('../views/Work.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(role.data >= 99){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
        Swal.fire({icon:'error', title: 'שגיאת הרשאה', text: 'אין לך את ההרשאה המתאימה'});
      }
    }
  },
  {
    path: '/clockin',
    name: 'clockin',
    component: () => import('../components/Shift/ClockIn.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(role.data >= 99){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
        Swal.fire({icon:'error', title: 'שגיאת הרשאה', text: 'אין לך את ההרשאה המתאימה'});
      }
    }
  },
  {
    path: '/clockout',
    name: 'clockout',
    component: () => import('../components/Shift/ClockOut.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(role.data >= 99){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
        Swal.fire({icon:'error', title: 'שגיאת הרשאה', text: 'אין לך את ההרשאה המתאימה'});
      }
    }
  },
  {
    path: '/servicein',
    name: 'servicein',
    component: () => import('../components/Shift/ServiceIn.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(role.data >= 99){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
        Swal.fire({icon:'error', title: 'שגיאת הרשאה', text: 'אין לך את ההרשאה המתאימה'});
      }
    }
  },
  {
    path: '/serviceout',
    name: 'serviceout',
    component: () => import('../components/Shift/ServiceOut.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(role.data >= 99){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
        Swal.fire({icon:'error', title: 'שגיאת הרשאה', text: 'אין לך את ההרשאה המתאימה'});
      }
    }
  },
  {
    path: '/shift',
    name: 'shift',
    component: () => import('../components/Shift/Shift.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(role.data >= 99){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
        Swal.fire({icon:'error', title: 'שגיאת הרשאה', text: 'אין לך את ההרשאה המתאימה'});
      }
    }
  },
  {
    path: '/lastshift',
    name: 'lastshift',
    component: () => import('../components/Shift/LastShift.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(role.data >= 99){
        next()
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
        Swal.fire({icon:'error', title: 'שגיאת הרשאה', text: 'אין לך את ההרשאה המתאימה'});
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Users/LogIn.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Users/Register.vue')
  },
  {
    path: '/employees',
    name: 'employees',
    component: () => import('../views/Employees/Employees.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(role.data >= 99){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
        Swal.fire({icon:'error', title: 'שגיאת הרשאה', text: 'אין לך את ההרשאה המתאימה'});
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
