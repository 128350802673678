<template>
  <div class="update-alert" v-if="show_update_alert">
    <p>קיים עדכון מערכת חדש</p>
    <el-button type="success" style="margin-top:15px; width:300px" class="wobble-hor-bottom" @click="update_system"> עדכן עכשיו!</el-button>
  </div>
  
  <MainLoader v-if="loader"/>

  <MainNav/>
  <MainMenu v-if="menu"/>
  
  <div class="main-grid" id="main-grid">
    <router-view/>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { computed, onMounted} from '@vue/runtime-core';
import store from './store';
import MainLoader from './components/Loaders/MainLoader.vue';
import MainNav from './components/Bars/MainBar.vue';
import MainMenu from './components/Menu/MainMenu.vue';

export default {
  components:{MainLoader, MainNav, MainMenu},
  setup(){

    const show_update_alert = ref(false);
    let sw_detailes = null;
    let refreshing = false;

    const user = ref(computed(() => {
      return store.state.user
    }))

    const loader = ref(computed(() => {
      return store.state.loader
    }))

    const menu = ref(computed(() => {
      return store.state.menu
    }))

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.addEventListener('serviceWorkerUpdateEvent', (e) => {
            sw_detailes = e.detail;
            show_update_alert.value = true;
    }, { once: true });
    navigator.serviceWorker.addEventListener( 'controllerchange', () => {
      if (refreshing) return;
      refreshing = true;
      window.location.reload();
    });

    const update_system = () => {
      show_update_alert.value = false;
      if(sw_detailes  || sw_detailes.waiting){
        sw_detailes.waiting.postMessage({type:'SKIP_WAITING'});
      }
    }

    onMounted(() => {
      window.addEventListener('resize', () => {
        if(document.activeElement.type && document.activeElement.type === 'text'){
          console.log("Test input, no resize.")
        }
        else{
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
      });
    })

    return{
      loader, show_update_alert, update_system, user,menu
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
:root{
  --main: #161D31;
  --secondary: #283046;
  --alert-purple: rgba(115,103,240,.12);
  --purple: #7367f0;
  --indigo: #6610f2;
  --danger: #ea5455;
  --warning: #f5953b;
  --success: #28c76f;
  --light: #f6f6f6;
  --yellow: #ffc107;
  --blue: #3d36a1;
}
*{
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;  
  box-sizing: border-box;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  font-weight: 300;
  direction: rtl;
}
.main-grid{
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  background: var(--main);
  direction: rtl;
  overflow-x: hidden;
}
::-webkit-scrollbar{
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 7px;
}
::-webkit-scrollbar-track{
  background: transparent;
}
::-webkit-scrollbar-thumb{
  background: linear-gradient(var(--main), var(--secondary)) ;
  border-radius: 5px;
  width: 5px;
}
::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px; 
}
::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
}
.update-alert{
  position: absolute;
  top: 0;
  z-index: 1000;
  left: 0;
  background: var(--purple);
  color: white;
  font-size: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 0 10px black;
}

@media only screen and (max-width: 780px) {
  ::-webkit-scrollbar{
    width: 0;
  }
}
@media only screen and (max-width: 600px) {
  ::-webkit-scrollbar{
    width: 0;
  }
}
</style>