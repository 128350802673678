<template>
    <div class="main-menu">
        <div class="main-box">
            <div class="close" @click="close">
                <i class="material-icons" style="margin-left:2px;">close</i>
            </div>
            <UserStatus/>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import UserStatus from './UserStatus.vue';
import store from '../../store';
export default { 
components:{UserStatus},
setup(){
    const close = () => {
        store.state.menu = false;
    }
    return{
        close
    }
}
}
</script>

<style scoped>
.main-menu{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.755);
    overflow: hidden;
    z-index: 1000;
    top: 0;
    left: 0;
}
.main-box{
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 360px;
    height: 100%;
    max-height: 50%;
    background: var(--secondary);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.438);
}
.close{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--main);
    color: var(--danger);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    left: 5px;
    box-shadow: 0 0 5px rgba(10, 10, 10, 0.418);
    cursor: pointer;
    z-index: 2;
}
</style>